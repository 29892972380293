import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab } from "@mui/material";
import { useCallback, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../shared/hooks/useFetch";
import { logError } from "../../../shared/logging";
import { parseSearchParamWithFallback } from "../../../shared/utilities/searchParamsHelper";
import adminApi from "../../api/adminApi";
import { useClientContext } from "../../context/ClientContext";
import FeatureToggleBoundary from "../common/FeatureToggleBoundary";
import GeneralPageHeader from "../common/GeneralPageHeader";
import ObjectTitle from "../common/ObjectTitle";
import FullHeightFlexPage from "../common/layout/FullHeightFlexPage";
import StretchedScrollableContent from "../common/layout/StretchedScrollableContent";
import TimeSeriesPeriods from "../pages/portfolio-monitoring/portfolio-companies/time-series/TimeSeriesPeriods";
import DeleteObjectDialog from "./DeleteObjectDialog";
import ObjectMainInfo from "./ObjectMainInfo";
import { getDataCatalogueEntityType } from "./entityTypesHelper";
import EntityCardFiles from "./files/EntityCardFiles";

interface Props {
  objectId: string;
  objectType: string;
  showDefaultBackButtonTo?: string;
  showLogoSection?: boolean;
}

const tabValues = ["main", "time_series", "files"] as const;
type TabValue = (typeof tabValues)[number];

const ObjectDetailsPage = ({ objectId, objectType, showDefaultBackButtonTo, showLogoSection }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasAnyPermission, isPortalFeatureEnabled } = useClientContext();
  const navigate = useNavigate();

  const getDetails = useCallback(() => adminApi.getObjectById(objectType, objectId), [objectId, objectType]);
  const [objectData, fetchDetailsError, { setData }] = useFetch(getDetails);

  const getObjectDefinition = useCallback(() => adminApi.getObjectDefinition(objectType), [objectType]);
  const [objectDefinitionWithPermissions, fetchDefinitionError] = useFetch(getObjectDefinition);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [selectedTab, setSelectedTab] = useState(parseSearchParamWithFallback(searchParams, "tab", tabValues));

  if (fetchDetailsError || fetchDefinitionError) {
    if (fetchDetailsError?.type === "NotFound") {
      return <Navigate to=".." />;
    }

    logError(fetchDetailsError || fetchDefinitionError, "[ObjectDetails]");
    return <DataLoadingFailed />;
  }

  if (!objectData || !objectDefinitionWithPermissions) {
    return <InlineLoader />;
  }

  const { objectClassDefinition, objectPermissions } = objectDefinitionWithPermissions;

  const handleDeleted = () => {
    setTimeout(() => navigate(".."));
  };

  const handleTabChange = (_: React.SyntheticEvent, tab: TabValue) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  const hasPermissionsToManageObjectData = hasAnyPermission(objectPermissions.objectDataWritePermissions);

  const areFilesSupported =
    hasAnyPermission(objectPermissions.filesReadPermissions) &&
    objectClassDefinition.supportedFeatures.includes("Files");

  const hasPermissionsToManageFiles = hasAnyPermission(objectPermissions.filesWritePermissions);

  const areNotesSupported =
    hasAnyPermission(objectPermissions.notesReadPermissions) &&
    objectClassDefinition.supportedFeatures.includes("Notes");

  const hasPermissionsToManageNotes = hasAnyPermission(objectPermissions.notesWritePermissions);

  const areTimeSeriesFieldsSupported = objectClassDefinition.supportedFeatures.includes("TimeSeriesFields");
  const isCustomSourceObject = objectDefinitionWithPermissions.objectClassDefinition.source === "Custom";

  return (
    <TabContext value={selectedTab}>
      <FullHeightFlexPage>
        <Box>
          <GeneralPageHeader
            TitleComponent={
              <ObjectTitle
                title={objectData.displayValues.primaryValue || objectData.name}
                secondaryTitle={objectData.displayValues.secondaryValue}
                typeName={objectClassDefinition.objectName}
                logoUrl={objectData.logoUrl}
              />
            }
            hideBorderLine
            showDefaultBackButtonTo={showDefaultBackButtonTo}
          >
            {isCustomSourceObject && hasPermissionsToManageObjectData && (
              <FeatureToggleBoundary featureToggle="CustomObjectsEnabled">
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => setShowDeleteDialog(true)}
                >
                  Delete
                </Button>
              </FeatureToggleBoundary>
            )}
          </GeneralPageHeader>
          <TabList onChange={handleTabChange}>
            <Tab value="main" label="Main Info" sx={{ ml: 3 }} />
            {areTimeSeriesFieldsSupported && isPortalFeatureEnabled("ShowTimeSeriesMockup") && (
              <Tab value="time_series" label="Time Series" />
            )}
            {areFilesSupported && isPortalFeatureEnabled("EntityFilesEnabled") && <Tab value="files" label="Files" />}
          </TabList>
        </Box>
        <StretchedScrollableContent>
          <TabPanel
            value="main"
            sx={{ height: "100%", m: 0, px: 3, pt: 2.5, pb: 0, backgroundColor: "background.grey" }}
          >
            <ObjectMainInfo
              objectData={objectData}
              objectDefinition={objectClassDefinition}
              hasPermissionsToManageObjectData={hasPermissionsToManageObjectData}
              showNotes={areNotesSupported}
              hasPermissionsToManageNotes={hasPermissionsToManageNotes}
              showLogoSection={showLogoSection}
              onEntityUpdate={setData}
            />
          </TabPanel>
          {areTimeSeriesFieldsSupported && (
            <FeatureToggleBoundary featureToggle="ShowTimeSeriesMockup">
              <TabPanel value="time_series" sx={{ height: "100%", m: 0, px: 0, py: 0 }}>
                <TimeSeriesPeriods companyId={objectData.id} companyName={objectData.name} />
              </TabPanel>
            </FeatureToggleBoundary>
          )}
          {areFilesSupported && (
            <FeatureToggleBoundary featureToggle="EntityFilesEnabled">
              <TabPanel value="files" sx={{ height: "100%", m: 0, px: 0, py: 0 }}>
                <EntityCardFiles
                  entityType={getDataCatalogueEntityType(objectClassDefinition.objectType)}
                  entityId={objectData.id}
                  entityName={objectData.name}
                  objectType={objectData.objectType}
                  hasPermissionsToManage={hasPermissionsToManageFiles}
                />
              </TabPanel>
            </FeatureToggleBoundary>
          )}
        </StretchedScrollableContent>
      </FullHeightFlexPage>
      <DeleteObjectDialog
        objectId={objectId}
        objectType={objectType}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onDeleted={handleDeleted}
      />
    </TabContext>
  );
};

export default ObjectDetailsPage;
